import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=af9daa6e&scoped=true&"
import script from "./Modal.vue?vue&type=script&lang=js&"
export * from "./Modal.vue?vue&type=script&lang=js&"
import style0 from "./Modal.vue?vue&type=style&index=0&id=af9daa6e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af9daa6e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CookiesConsent: require('/app/components/cookies/Consent.vue').default})
